import "core-js/features/array/flat"; // requires core-js 3
import "polyfill-array-includes";
import "es6-shim";

import smoothscroll from "smoothscroll-polyfill";
import "srcdoc-polyfill";
import "url-search-params-polyfill";
import "unfetch/polyfill";

smoothscroll.polyfill();
